<template>
  <div class="container">
    <div class="box" v-if="product">
      <div class="columns">
        <div class="column is-half-desktop">
          <img class="product-image" :src="product.imageUrl">
        </div>
        <div class="column is-half-desktop product">
          <h1 class="title">{{ product.name }}</h1>
          <p>{{ product.description }}</p>
          <div class="product-price">
            <img src="@/assets/images/christmas-coin.png" height="48px" width="48px">
            {{ product.price }}
          </div>
          <div class="add-to-cart">
            <template v-if="stock > 0">
              <Counter v-model="product.selectedAmount" :min="1" :max="stock" class="pt-2 is-justify-content-center"/>
              <div class="button is-primary mt-2 is-block" @click="addToCart()" :disabled="!product.selectedAmount">Add to cart</div>
            </template>
            <div v-else class="notification is-danger is-light sold-out-notice">
              Sold out
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../services/http";
import { toast } from "bulma-toast";
import { mapGetters } from "vuex";
import Counter from "../components/Counter";

export default {
  name: "ProductDetail",
  components: {
    Counter
  },
  props: {
    productID: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    stock() {
      const inCart = this.cart.find(p => p.id === this.product.id)?.amount || 0;
      return this.product.amount - inCart;
    },
    ...mapGetters(['cart'])
  },
  data() {
    return {
      product: null
    }
  },
  methods: {
    addToCart() {
      if (!this.product.selectedAmount) return;
      this.$store.commit('addToCart', this.product);
      this.product.selectedAmount = 1;
      toast({
        message: 'The item has been added to your cart.',
        type: 'is-primary',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' }
      });
    },
  },
  created() {
    http.axios.get(`/inventory-service/products/${this.productID}`).then(res => {
      const product = res.data.data;
      product.selectedAmount = 1;
      this.product = product;
    }).catch(() => {
      // Order doesn't exist
      this.$router.push('/');
    });
  }
}
</script>

<style lang="scss" scoped>
.product-image {
  display: block;
  height: 400px;
  margin: auto;
}

.product {
  .product-price {
    font-size: 2.5rem;
    padding-top: 1rem;
    display: flex;
    align-items: center;

    img {
      margin-right: 0.5rem;
    }
  }

  .add-to-cart {
    padding-top: 2rem;
    text-align: center;
  }
}
</style>
