<template>
  <div class="navbar is-spaced" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">
        Christmas Market Twente
      </router-link>

      <div class="navbar-burger" @click="showNav = !showNav" :class="{ 'is-active': showNav }">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </div>
    </div>

    <div id="navbar" class="navbar-menu" :class="{ 'is-active': showNav }">
      <div class="navbar-start">
        <router-link to="/about" class="navbar-item">About</router-link>
      </div>
       <div class="navbar-end">
        <router-link class="navbar-item" to="/cart">
          <cart-icon/>
        </router-link>
        <template v-if="isLoggedIn">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              <account-icon/>
            </a>

            <div class="navbar-dropdown is-right">
              <div class="navbar-item" v-if="user">
                {{ user.firstName }} {{ user.lastName }}
              </div>
              <hr class="navbar-divider">
              <router-link to="/tokens" class="navbar-item">
                My Christmas Tokens
              </router-link>
              <router-link to="/orders" class="navbar-item">
                My Orders
              </router-link>
              <a class="navbar-item">
                Account
              </a>
              <hr class="navbar-divider">
              <a class="navbar-item" @click="logout">
                Logout
              </a>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="navbar-item">
            <div class="buttons">
              <router-link to="/register" class="button is-primary">
                <strong>Sign up</strong>
              </router-link>
              <router-link to="/login" class="button is-light">
                Log in
              </router-link>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import http from "@/services/http";
import AccountIcon from "@/assets/icons/account.svg";
import CartIcon from "@/assets/icons/cart.svg";

export default {
  name: "Navbar.vue",
  components: {
    AccountIcon,
    CartIcon
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user'])
  },
  data() {
    return {
      showNav: false
    }
  },
  methods: {
    logout() {
      http.logout();
      this.$router.push({ name: 'login' });
    }
  }
}
</script>

<style scoped>

</style>
