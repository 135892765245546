<template>
  <div class="container">
    <div class="columns is-justify-content-center">
      <div class="column is-half">
        <div class="box">
          <router-link to="/login">&#10094; Back</router-link>
          <h1 class="title">Register</h1>
          <form @submit.prevent="register">
            <div class="field">
              <label class="label">First name</label>
              <div class="control">
                <input v-model="user.firstName" class="input" :class="{ 'is-danger': errors.firstName }" placeholder="John" @input="onInput('firstName')">
              </div>
            </div>
            <div class="field">
              <label class="label">Last name</label>
              <div class="control">
                <input v-model="user.lastName" class="input" :class="{ 'is-danger': errors.lastName }" placeholder="Doe" @input="onInput('lastName')">
              </div>
            </div>
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input v-model="user.email" class="input" :class="{ 'is-danger': errors.email }" type="email" placeholder="email@domain.org" @input="onInput('email')">
              </div>
            </div>
            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input v-model="user.password" class="input" :class="{ 'is-danger': errors.password }" type="password" placeholder="Password" @input="onInput('password')">
              </div>
            </div>
            <div class="field">
              <label class="label">Repeat password</label>
              <div class="control">
                <input v-model="repeatPassword" class="input" :class="{ 'is-danger': errors.repeatPassword }" type="password" placeholder="Repeat password" @input="onInput('repeatPassword')">
              </div>
            </div>
            <div class="field">
              <div class="control">
                <button class="button is-primary">Register</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/services/http";
import {toast} from "bulma-toast";

export default {
  name: "Register",
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      repeatPassword: '',
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        password: false
      }
    }
  },
  methods: {
    register() {
      if (!this.validate()) return;

      http.axios.post('/identity-service/auth/register', this.user).then(res => {
        http.afterLogin(res.data.data.token);
        this.$router.push('/');
        toast({
          message: 'You were successfully registered.',
          type: 'is-primary',
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
      }).catch(err => {
        if (err.response && err.response.status === 409) {
          this.errors.email = true;
          toast({
            message: 'The email address has already been registered.',
            type: 'is-danger',
            dismissible: true,
            animate: { in: 'fadeIn', out: 'fadeOut' }
          });
        }
        else {
          toast({
            message: 'An unknown error occurred. Please try again later.',
            type: 'is-danger',
            dismissible: true,
            animate: { in: 'fadeIn', out: 'fadeOut' }
          });
        }
      });
    },
    onInput(field) {
      this.errors[field] = false;
    },
    validate() {
      let valid = true;

      if (!this.user.firstName) {
        this.errors.firstName = true;
        valid = false;
      }

      if (!this.user.lastName) {
        this.errors.lastName = true;
        valid = false;
      }

      if (!this.user.email) {
        this.errors.email = true;
        valid = false;
      }

      if (this.user.password.length < 8) {
        this.errors.password = true;
        valid = false;
      }

      if (!this.repeatPassword) {
        this.errors['repeatPassword'] = true;
        valid = false;
      }

      if (this.user.password !== this.repeatPassword) {
        this.errors['password'] = true;
        this.errors['repeatPassword'] = true;
        valid = false;
      }

      return valid;
    }
  }
}
</script>

<style scoped>

</style>
