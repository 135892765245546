import axios from "axios";
import Store from '@/store';
import Router from '@/router';
import config from "@/config";

axios.defaults.baseURL = config.apiBaseUrl;

let interceptor;

if (localStorage.getItem('token') !== null) {
  afterLogin(localStorage.getItem('token'));
}

function enableInterceptor() {
  interceptor = axios.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 401) {
      logout();
      if (Router.currentRoute.name !== 'login') {
        Router.push({ name: 'login', query: { redirect: Router.currentRoute.fullPath } })
      }
    }
    return Promise.reject(error);
  });
}


function afterLogin(token) {
  localStorage.setItem('token', token);
  axios.defaults.headers.common['X-Authorization'] = `Bearer ${token}`;
  Store.commit('setIsLoggedIn', true);
  enableInterceptor();
}

function logout() {
  localStorage.removeItem('token');
  axios.interceptors.response.eject(interceptor);
  delete axios.defaults.headers.common['X-Authorization'];
  Store.commit('setIsLoggedIn', false);
  Store.commit('setUser', null);
}

export default {
  afterLogin,
  logout,
  axios
}
