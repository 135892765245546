<template>
  <div class="container">
    <div class="box" v-if="order">
      <router-link to="/orders">&#10094; Back</router-link>
      <h1 class="title">Order #{{ order.id }}</h1>
      <table class="order-table">
        <tr>
          <th>Ordered at:</th>
          <td>{{ new Date(order.createdAt * 1000).toDateString() }}</td>
        </tr>
        <tr>
          <th>Webshop purchase:</th>
          <td>{{ order.isWebshopPurchase ? 'Yes' : 'No' }}</td>
        </tr>
        <tr>
          <th>Total price:</th>
          <td>{{ totalPrice }} {{ tokenUnit(totalPrice) }}</td>
        </tr>
        <tr v-if="order.isWebshopPurchase">
          <th>Shipment status:</th>
          <td>{{ shipmentStatus }}</td>
        </tr>
      </table>

      <table class="table is-fullwidth is-hoverable mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th>Amount</th>
            <th>Price per piece</th>
            <th class="has-text-right">Total price</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in order.products" :key="product.id">
            <td>{{ getProductName(product.productId) }}</td>
            <td>{{ product.amount }}</td>
            <td>{{ product.unitPrice }}</td>
            <td class="has-text-right">{{ product.amount * product.unitPrice }}</td>
          </tr>
          <tr>
            <td><strong>Total</strong></td>
            <td></td>
            <td></td>
            <td class="has-text-right"><strong>{{ totalPrice }} {{ tokenUnit(totalPrice) }}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import http from "@/services/http";
import { mapGetters } from "vuex";
import util from "@/services/util";

export default {
  name: "Order",
  props: {
    orderID: {
      type: [String, Number],
      required: true
    },
  },
  computed: {
    totalPrice() {
      return this.order.products.reduce((acc, curr) => acc + curr.amount * curr.unitPrice, 0);
    },
    shipmentStatus() {
      if (this.order.shippedAt) return "Shipped";
      if (this.order.shipmentAcceptedAt) return "Scheduled";
      return "Waiting to be processed";
    },
    ...mapGetters(['products'])
  },
  data() {
    return {
      order: null
    }
  },
  methods: {
    tokenUnit: util.tokenUnit,
    getProductName(productId) {
      const product = this.products.find(p => p.id === productId);
      return product ? product.name : 'Unknown';
    }
  },
  created() {
    http.axios.get(`/webshop/orders/${this.orderID}`).then(res => {
      this.order = res.data.data;
    }).catch(() => {
      // Order doesn't exist
      this.$router.push('/orders');
    });
  }
}
</script>

<style scoped lang="scss">
.order-table {
  th {
    padding-right: 20px;
  }
}

</style>
