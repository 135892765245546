import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Cart from '@/views/Cart.vue';
import Checkout from '@/views/Checkout';
import Login from '@/views/Login';
import Tokens from '@/views/Tokens';
import Orders from "@/views/Orders";
import Order from "@/views/Order";
import Register from "@/views/Register";
import ProductDetail from "@/views/ProductDetail";
import About from "@/views/About";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/tokens',
    name: 'tokens',
    component: Tokens,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders/:orderID',
    name: 'order',
    component: Order,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products/:productID/:slug?',
    name: 'productDetail',
    component: ProductDetail,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth && !localStorage.getItem('token'))) {
    return next({ name: 'login', query: { redirect: to.fullPath } });
  }
  next();
});

export default router;
