function tokenUnit(amount) {
  return amount === 1
      ? 'Christmas Token'
      : 'Christmas Tokens';
}

function saveCart(cart) {
  localStorage.setItem('cart', JSON.stringify(cart));
}

export default {
  tokenUnit,
  saveCart
}
