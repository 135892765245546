<template>
  <div class="container">
    <div class="box">
      <h1 class="title">My Tokens</h1>
      <div class="columns is-vcentered">
        <div class="column is-narrow pr-1">
          <img src="@/assets/images/christmas-coin.png" height="32px" width="32px">
        </div>
        <div class="column pl-1">
          <span>{{ balance }}</span>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <button class="button is-primary" @click="buyTokensOpen = !buyTokensOpen">
            Buy more tokens
            <chevron-up-icon v-if="buyTokensOpen" class="chevron-icon"/>
            <chevron-down-icon v-else class="chevron-icon"/>
          </button>
        </div>
      </div>

      <div class="columns" v-if="buyTokensOpen">
        <div class="column is-one-quarter is-full-mobile">
          <form @submit.prevent="buyTokens">
            <div class="field">
              <label class="label">Amount</label>
              <div class="control">
                <input v-model="tokenAmount" class="input" type="number" min="0" step="1" placeholder="10">
              </div>
            </div>

            <div class="notification">
              Price: € {{ (tokenAmount * 0.5).toFixed(2) }}
            </div>

            <div class="field">
              <div class="control">
                <button class="button ideal-button" :disabled="!tokenAmount">
                  <IDealIcon class="ideal-icon"/>
                  Pay with iDEAL
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChevronUpIcon from "@/assets/icons/chevron-up.svg";
import ChevronDownIcon from "@/assets/icons/chevron-down.svg";
import IDealIcon from "@/assets/icons/ideal-logo.svg";
import http from "@/services/http";
import {toast} from "bulma-toast";

export default {
  name: "Tokens",
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
    IDealIcon
  },
  data() {
    return {
      buyTokensOpen: false,
      tokenAmount: null
    }
  },
  computed: {
    ...mapGetters(['balance'])
  },
  methods: {
    buyTokens() {
      http.axios.post('/token-service/tokens/buy', {
        amount: this.tokenAmount,
        paymentIntent: 'abc'
      }).then(res => {
        this.$store.commit('setBalance', res.data.data.balance);
        this.tokenAmount = null;
        this.buyTokensOpen = false;
        toast({
          message: 'Payment succeeded. The tokens have been added to your account.',
          type: 'is-primary',
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
      }).catch(() => {
        toast({
          message: 'An error occurred during payment. Please try again later.',
          type: 'is-danger',
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
.chevron-icon {
  fill: white;
}

.ideal-button {
  color: white;
  border: 1px solid #cc0066;
  background-color: #cc0066;

  .ideal-icon {
    height: 24px;
    width: 24px;
    color: white;
    margin-right: 10px;

    .st0 {
      fill: white;
    }
  }
}
</style>
