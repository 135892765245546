<template>
  <div class="container">
    <div class="columns is-justify-content-center">
      <div class="column is-half">
        <div class="box">
          <h1 class="title">Login</h1>
          <form @submit.prevent="login">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input v-model="email" class="input" :class="{ 'is-danger': hasError }" type="email" placeholder="email@domain.org" @input="onInput">
              </div>
            </div>
            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input v-model="password" class="input" :class="{ 'is-danger': hasError }" type="password" placeholder="Password" @input="onInput">
              </div>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button class="button is-primary">Log in</button>
              </div>
              <div class="control">
                <router-link to="/register" class="button is-light">Sign up</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/services/http";
import { toast } from "bulma-toast";

export default {
  name: "Login",
  data() {
    return {
      email: '',
      password: '',
      hasError: false
    }
  },
  methods: {
    login() {
      http.axios.post('/identity-service/auth/login', {
        email: this.email,
        password: this.password
      }).then(res => {
        http.afterLogin(res.data.data.token);
        this.$router.push(this.$route.query.redirect || '/');
      }).catch(() => {
        this.hasError = true;
        toast({
          message: 'Email or password incorrect',
          type: 'is-danger',
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
      });
    },
    onInput() {
      this.hasError = false;
    }
  }
}
</script>

<style scoped>

</style>
