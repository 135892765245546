<template>
  <div id="app">
    <navbar></navbar>
    <router-view/>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar';
import {mapGetters} from "vuex";

export default {
  components: {
    Navbar
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  watch: {
    isLoggedIn(val) {
      if (val) {
        this.fetchData();
      }
    }
  },
  methods: {
    fetchData() {
      this.$store.dispatch('fetchUser');
      this.$store.dispatch('fetchProducts');
      this.$store.dispatch('fetchBalance');
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.fetchData();
    }

    if (localStorage.getItem('cart')) {
      this.$store.commit('setCart', JSON.parse(localStorage.getItem('cart')));
    }
  }
}
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}

.box {
  text-align: left;
}
</style>
