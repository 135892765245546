<template>
  <div>
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">Products</h1>
        </div>
      </div>
      <div class="columns is-multiline columns-full-height">
        <div v-for="product in products" :key="product.id" class="column is-one-quarter">
          <div class="product box has-text-centered box-full-height">
            <div @click="goToProductDetail(product)" class="pointer">
              <img class="image product-image" :src="product.imageUrl">
              <h3 class="pt-2 is-size-4">{{ product.name }}</h3>
              <p class="is-size-7">{{ product.price }} {{ tokenUnit(product.price) }}</p>
            </div>
            <template v-if="getProductStock(product) > 0">
              <Counter v-model="product.selectedAmount" :min="1" :max="getProductStock(product)" class="pt-2 is-justify-content-center"/>
              <div class="button is-primary mt-2" @click="addToCart(product)" :disabled="!product.selectedAmount">Add to cart</div>
            </template>
            <div v-else class="notification is-danger is-light sold-out-notice">
              Sold out
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Counter from "@/components/Counter";
import { mapGetters } from "vuex";
import { toast } from "bulma-toast";
import util from "@/services/util";

export default {
  name: 'Home',
  components: {
    Counter
  },
  computed: {
    ...mapGetters(['cart', 'products'])
  },
  methods: {
    getProductStock(product) {
      const inCart = this.cart.find(p => p.id === product.id)?.amount || 0;
      return product.amount - inCart;
    },
    addToCart(product) {
      if (!product.selectedAmount) return;
      this.$store.commit('addToCart', product);
      product.selectedAmount = 1;
      toast({
        message: 'The item has been added to your cart.',
        type: 'is-primary',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' }
      });
    },
    goToProductDetail(product) {
      this.$router.push({ name: 'productDetail', params: { productID: product.id, slug: this.toSlug(product.name) }});
    },
    toSlug(string) {
      return string
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g,'');
    },
    tokenUnit: util.tokenUnit
  }
}
</script>

<style scoped lang="scss">
.columns-full-height {
  align-items: stretch;
}

.product {
  height: 100%;

  .product-image {
    height: 200px;
    margin: auto;
  }

  .sold-out-notice {
    margin-top: 1.25rem;
  }
}
</style>
