<template>
  <div class="container">
    <div class="box">
      <h1 class="title">About</h1>
      <p>This website has been created as a project of the master course <a href="https://osiris.utwente.nl/student/OnderwijsCatalogusSelect.do?selectie=cursus&cursus=192652150&collegejaar=2020&taal=en" target="_blank">
        Service-oriented Architecture using Web Services</a> of the University of Twente. This project was created by
        <a href="https://roelink.eu" target="_blank">Matthijs Roelink</a> and <a href="https://enschedelly.nl" target="_blank">Nikki Zandbergen</a>.
        For this project, we have built a service-oriented architecture for the fictional company Christmas Market Twente.
        On this page you can download the project report and the presentation slides, and find links to all implemented
        microservices in GitLab. This project has been graded with an 8.5!
      </p>
    </div>
    <div class="columns">
      <div class="column">
        <div class="box">
          <h2 class="is-size-4 has-text-weight-semibold">Report</h2>
          <a href="/files/CMT_Final_Delivery.pdf" download="">
            <span class="icon-text">
              <span>Download</span>
              <span class="icon ml-5">
                <download-icon class="small"/>
              </span>
            </span>
          </a>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <h2 class="is-size-4 has-text-weight-semibold">Presentation slides</h2>
          <a href="/files/Group24_SOA_final_presentation.pdf" download="">
            <span class="icon-text">
              <span>Download</span>
              <span class="icon ml-5">
                <download-icon class="small"/>
              </span>
            </span>
          </a>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <h2 class="is-size-4 has-text-weight-semibold">Code</h2>
          <a href="https://gitlab.com/christmas-market-twente" target="_blank">
            <span class="icon-text">
              <span>GitLab</span>
              <span class="icon ml-5">
                <open-in-new-icon class="small"/>
              </span>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="box mb-4">
      <h2 class="is-size-4 has-text-weight-semibold">README</h2>
      <div class="markdown-body pt-2">
        <pre><code>   _____ _          _     _                         __  __            _        _     _______                 _
  / ____|<span class="hljs-string"> </span>|<span class="hljs-string">        (_)   </span>|<span class="hljs-string"> </span>|<span class="hljs-string">                       </span>|<span class="hljs-string">  \/  </span>|<span class="hljs-string">          </span>|<span class="hljs-string"> </span>|<span class="hljs-string">      </span>|<span class="hljs-string"> </span>|<span class="hljs-string">   </span>|<span class="hljs-string">__   __</span>|<span class="hljs-string">               </span>|<span class="hljs-string"> </span>|<span class="hljs-string">
 </span>|<span class="hljs-string"> </span>|<span class="hljs-string">    </span>|<span class="hljs-string"> </span>|<span class="hljs-string">__  _ __ _ ___</span>|<span class="hljs-string"> </span>|<span class="hljs-string">_ _ __ ___   __ _ ___  </span>|<span class="hljs-string"> \  / </span>|<span class="hljs-string"> __ _ _ __</span>|<span class="hljs-string"> </span>|<span class="hljs-string"> _____</span>|<span class="hljs-string"> </span>|<span class="hljs-string">_     </span>|<span class="hljs-string"> </span>|<span class="hljs-string">_      _____ _ __ </span>|<span class="hljs-string"> </span>|<span class="hljs-string">_ ___
 </span>|<span class="hljs-string"> </span>|<span class="hljs-string">    </span>|<span class="hljs-string"> '_ \</span>|<span class="hljs-string"> '__</span>|<span class="hljs-string"> / __</span>|<span class="hljs-string"> __</span>|<span class="hljs-string"> '_ ` _ \ / _` / __</span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string">\/</span>|<span class="hljs-string"> </span>|<span class="hljs-string">/ _` </span>|<span class="hljs-string"> '__</span>|<span class="hljs-string"> </span>|<span class="hljs-string">/ / _ \ __</span>|<span class="hljs-string">    </span>|<span class="hljs-string"> \ \ /\ / / _ \ '_ \</span>|<span class="hljs-string"> __/ _ \
 </span>|<span class="hljs-string"> </span>|<span class="hljs-string">____</span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string">  </span>|<span class="hljs-string"> \__ \ </span>|<span class="hljs-string">_</span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string"> (_</span>|<span class="hljs-string"> \__ \ </span>|<span class="hljs-string"> </span>|<span class="hljs-string">  </span>|<span class="hljs-string"> </span>|<span class="hljs-string"> (_</span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string">  </span>|<span class="hljs-string">   &lt;  __/ </span>|<span class="hljs-string">_     </span>|<span class="hljs-string"> </span>|<span class="hljs-string">\ V  V /  __/ </span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>|<span class="hljs-string"> </span>||<span class="hljs-string">  __/
  \_____</span>|<span class="hljs-string">_</span>|<span class="hljs-string"> </span>|<span class="hljs-string">_</span>|<span class="hljs-string">_</span>|<span class="hljs-string">  </span>|<span class="hljs-string">_</span>|<span class="hljs-string">___/\__</span>|<span class="hljs-string">_</span>|<span class="hljs-string"> </span>|<span class="hljs-string">_</span>|<span class="hljs-string"> </span>|<span class="hljs-string">_</span>|<span class="hljs-string">\__,_</span>|<span class="hljs-string">___/ </span>|<span class="hljs-string">_</span>|<span class="hljs-string">  </span>|<span class="hljs-string">_</span>|<span class="hljs-string">\__,_</span>|<span class="hljs-string">_</span>|<span class="hljs-string">  </span>|<span class="hljs-string">_</span>|<span class="hljs-string">\_\___</span>|<span class="hljs-string">\__</span>|<span class="hljs-string">    </span>|<span class="hljs-string">_</span>|<span class="hljs-string"> \_/\_/ \___</span>|<span class="hljs-string">_</span>|<span class="hljs-string"> </span>|<span class="hljs-string">_</span>|<span class="hljs-string">\__\___</span>|
</code></pre><p>This is the README file of the project &quot;Christmas Market Twente&quot; of group 24 for the Service Oriented Architecture with Web Services course.</p>
        <h1 id="-optional-build-the-docker-images">(Optional) Build the Docker images</h1>
        <p>The Docker images of the services have been pushed to the <a href="https://hub.docker.com/orgs/christmasmarkettwente/repositories">Docker Hub</a>. All services can be manually built in the following way:</p>
        <pre><code class="lang-bash"><span class="hljs-keyword">cd</span> services/<span class="hljs-symbol">&lt;service&gt;</span>
docker build -t christmasmarkettwente/<span class="hljs-symbol">&lt;service&gt;</span> .
</code></pre>
        <p>Additionally, the shipping service requires a dockerized ActiveMQ, which can be built in the following way:</p>
        <pre><code class="lang-bash"><span class="hljs-symbol">cd</span> services/shipping-service/activemq
<span class="hljs-symbol">docker</span> <span class="hljs-keyword">build </span>-t christmasmarkettwente/shipping-service-activemq .
</code></pre>
        <h1 id="running-the-cluster">Running the cluster</h1>
        <p>This sections describes how the Kubernetes cluster should be set up to run our application. Our application is also available at <a href="https://cmt.enschedelly.nl">https://cmt.enschedelly.nl</a>.</p>
        <h2 id="setting-up-the-database">Setting up the database</h2>
        <p>First of all, most services require a database to store and retrieve data. This is a MySQL database and is expected to run on the localhost on port <code>3306</code>, with a user <code>root</code> with password <code>root</code>. Next to that, separate databases need to be created. This can either be done with the <code>setup-db</code> script (only supported for Linux/MacOS):</p>
        <blockquote>
          <p>Use <code>-h</code> to view all supported options.</p>
        </blockquote>
        <pre><code class="lang-bash">chmod +x scripts/setup-<span class="hljs-keyword">db</span>.<span class="hljs-keyword">sh</span>
./scripts/setup-<span class="hljs-keyword">db</span>.<span class="hljs-keyword">sh</span> -<span class="hljs-keyword">u</span> &lt;username&gt;
</code></pre>
        <p>or manually:</p>
        <pre><code class="lang-bash">mysql -u &lt;username&gt; -p
mysql&gt; CREATE USER <span class="hljs-string">'root'</span>@<span class="hljs-string">'%'</span> IDENTIFIED BY <span class="hljs-string">'root'</span>;
mysql&gt; GRANT ALL PRIVILEGES ON *.* TO <span class="hljs-string">'root'</span>@<span class="hljs-string">'%'</span>;
mysql&gt; FLUSH PRIVILEGES;

mysql&gt; CREATE DATABASE `cmt-identity-service`;
mysql&gt; CREATE DATABASE `cmt-inventory-service`;
mysql&gt; CREATE DATABASE `cmt-shipping-service`;
mysql&gt; CREATE DATABASE `cmt-token-service`;
mysql&gt; CREATE DATABASE `cmt-webshop`;
</code></pre>
        <h2 id="deploying-the-services">Deploying the services</h2>
        <p>Next, all services and deployments should be created in Kubernetes. This can be done using the <code>deploy-services</code> script provided in the <code>scripts</code> directory (only supported for Linux/MacOS):</p>
        <pre><code class="lang-bash">chmod +x scripts/deploy-services.<span class="hljs-keyword">sh</span>
./scripts/deploy-services.<span class="hljs-keyword">sh</span>
</code></pre>
        <p>Alternatively, all <code>deployment.yaml</code> files in each service folder can be applied manually:</p>
        <pre><code class="lang-bash">kubectl apply -f services/documentation-service/deployment<span class="hljs-selector-class">.yaml</span>
kubectl apply -f services/identity-service/deployment<span class="hljs-selector-class">.yaml</span>
kubectl apply -f services/inventory-service/deployment<span class="hljs-selector-class">.yaml</span>
kubectl apply -f services/shipping-service/deployment<span class="hljs-selector-class">.yaml</span>
kubectl apply -f services/token-service/deployment<span class="hljs-selector-class">.yaml</span>
kubectl apply -f services/webshop/deployment<span class="hljs-selector-class">.yaml</span>
kubectl apply -f services/webshop-frontend/deployment.yaml
</code></pre>
        <h2 id="setting-up-ingress">Setting up Ingress</h2>
        <p>To support service discovery, load balancing and authentication, we use <code>ingress-nginx</code>. Similar to the previous steps, we have provided a script for deploying <code>ingress-nginx</code> (only supported for Linux/MacOS): </p>
        <pre><code class="lang-bash">chmod +x scripts/deploy-ingress.<span class="hljs-keyword">sh</span>
./scripts/deploy-ingress.<span class="hljs-keyword">sh</span>
</code></pre>
        <p>This can be manually installed in the following way:</p>
        <pre><code class="lang-bash">kubectl apply -f https:<span class="hljs-regexp">//</span>raw.githubusercontent.com<span class="hljs-regexp">/kubernetes/i</span>ngress-nginx<span class="hljs-regexp">/controller-v0.44.0/</span>deploy<span class="hljs-regexp">/static/</span>provider<span class="hljs-regexp">/cloud/</span>deploy.yaml
</code></pre>
        <p>Then, our routes can be defined by applying the two ingress configuration files:</p>
        <pre><code class="lang-bash">kubectl apply -f kubernetes-<span class="hljs-built_in">config</span>/ingress/ingress.yaml
kubectl apply -f kubernetes-<span class="hljs-built_in">config</span>/ingress/ingress-auth.yaml
</code></pre>
        <p>The application should now be up and running and available on <a href="http://localhost">http://localhost</a>.</p>
        <h1 id="documentation">Documentation</h1>
        <p>The Documentation Service provides a SwaggerUI instance at <a href="http://localhost/api/documentation-service/docs">http://localhost/api/documentation-service/docs</a> which combines all OpenAPI specifications of the services. Note that it might take some time before all services have subscribed to the Documentation Service, at most approximately ten minutes. All seperate OpenAPI specifications can be found at <code>/api/&lt;service&gt;/api-docs</code> for all services except the Documentation Service.</p>
        <h1 id="other">Other</h1>
        <p>You may notice that each service contains a folder named <code>.deploy</code>. This folder is used for deployment to the production environment (<a href="https://cmt.enschedelly.nl">https://cmt.enschedelly.nl</a>) only and is thus not part of the local cluster.</p>

      </div>
    </div>
  </div>
</template>

<script>
import DownloadIcon from "@/assets/icons/download.svg";
import OpenInNewIcon from "@/assets/icons/open-in-new.svg"

export default {
  name: "About",
  components: {
    DownloadIcon,
    OpenInNewIcon
  }
}
</script>

<style scoped>
.small {
  margin-bottom: 0;
}
</style>

<style src="github-markdown-css/github-markdown.css"></style>