<template>
  <div class="container small-container">
    <div class="box">
      <h1 class="title">Checkout</h1>
      <template v-if="productsInCart.length === 0">
        <p>Error: your cart is empty.</p>
      </template>
      <template v-else>
        <table class="checkout-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Amount</th>
              <th>Price per piece</th>
              <th class="has-text-right">Total price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in productsInCart" :key="product.id">
              <td>{{ product.name }}</td>
              <td>{{ product.selectedAmount }}</td>
              <td>{{ product.price }} {{ tokenUnit(product.price) }}</td>
              <td class="has-text-right">{{ product.selectedAmount * product.price }} {{ tokenUnit(product.amount * product.price) }}</td>
            </tr>
            <tr>
              <td><strong>Total</strong></td>
              <td></td>
              <td></td>
              <td class="has-text-right"><strong>{{ totalPrice }} {{ tokenUnit(totalPrice) }}</strong></td>
            </tr>
          </tbody>
        </table>
        <div class="columns pt-4">
          <div class="column">
            <router-link to="/" class="button is-danger">
              Cancel order
            </router-link>
            <div class="button is-primary is-pulled-right" :class="{ 'is-loading': this.loading }" @click="pay()" :disabled="hasInsufficientBalance">
              Confirm and pay order
            </div>
          </div>
        </div>

        <div v-if="hasInsufficientBalance" class="columns mb-0">
          <div class="column py-0">
            <span class="is-pulled-right insufficient-balance-notice has-text-danger">You don't have enough tokens.</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import util from "@/services/util";
import http from "@/services/http";
import { toast } from "bulma-toast";

export default {
  name: "Checkout",
  computed: {
    productsInCart() {
      return this.cart
        .filter(p => p.amount > 0)
        .map(p => ({
            ...this.products.find(pr => pr.id === p.id),
            selectedAmount: p.amount
      }));
    },
    totalPrice() {
      return this.productsInCart.reduce((acc, curr) => acc + curr.selectedAmount * curr.price, 0)
    },
    hasInsufficientBalance() {
      return this.balance < this.totalPrice;
    },
    ...mapGetters(['balance', 'cart', 'products'])
  },
  data() {
    return {
      isWebshopPurchase: true,
      loading: false
    }
  },
  methods: {
    tokenUnit: util.tokenUnit,
    pay() {
      if (this.hasInsufficientBalance) return;

      this.loading = true;
      http.axios.post('/webshop/purchase', {
        isWebshopPurchase: this.isWebshopPurchase,
        products: this.cart.map(p => ({
          productId: p.id,
          amount: p.amount
        }))
      }).then(res => {
        this.loading = false;
        toast({
          message: 'Payment succeeded. You can find the order under "My Orders".',
          type: 'is-primary',
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
        this.$store.commit("setCart", []);
        this.$store.dispatch('fetchBalance');
        this.$router.push({ name: 'order', params: { orderID: res.data.data.id } })
      }).catch(() => {
        this.loading = false;
        toast({
          message: 'An error occurred when processing your order. No funds have been withdrawn from your account. Please try again later.',
          type: 'is-danger',
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
      });
    }
  },
  created() {
    if (this.$route.query.cart) {
      this.$store.commit('setCart', JSON.parse(decodeURIComponent(this.$route.query.cart)));
      this.isWebshopPurchase = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.small-container {
  max-width: 960px !important;
}

.checkout-table {
  width: 100%;

  th, td {
    height: 2.5rem;
  }
}

.insufficient-balance-notice {
  font-size: 0.85rem;
}
</style>
