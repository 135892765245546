<template>
  <div class="container">
    <div class="box">
      <h1 class="title">My orders</h1>
      <p v-if="orders.length === 0">Your have not yet placed any orders. Placed orders will be visible here.</p>
      <table v-else class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Ordered at</th>
            <th>Total price</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order.id" class="pointer" @click="goToOrder(order)">
            <td>{{ order.id }}</td>
            <td>{{ new Date(order.createdAt * 1000).toDateString() }}</td>
            <td>{{ order.products.reduce((acc, curr) => acc + curr.amount * curr.unitPrice, 0) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import http from "@/services/http";
import { toast } from "bulma-toast";

export default {
  name: "Orders",
  data() {
    return {
      orders: []
    }
  },
  methods: {
    goToOrder(order) {
      this.$router.push({ name: 'order', params: { orderID: order.id } });
    }
  },
  created() {
    http.axios.get('/webshop/orders').then(res => {
        this.orders = res.data.data;
    }).catch(() => {
      toast({
        message: 'An unknown error occurred. Please come back later.',
        type: 'is-danger',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' }
      });
    });
  }
}
</script>

<style scoped>

</style>
