<template>
  <div class="is-align-items-center is-flex">
    <div class="button is-rounded mr-2" :class="{ 'is-small': small }" @click="decrement" :disabled="min !== null && this.value === min">-</div>
    <span>{{ value }}</span>
    <div class="button is-rounded ml-2" :class="{ 'is-small': small }" @click="increment" :disabled="max !== null && this.value === max">+</div>
  </div>
</template>

<script>
export default {
  name: "Counter",
  props: {
    value: {
      type: Number,
      required: false,
      default: 0
    },
    min: {
      type: Number,
      required: false,
      default: null
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    increment() {
      if (this.max !== null && this.value === this.max) return;
      this.$emit('input', this.value + 1);
    },
    decrement() {
      if (this.min !== null && this.value === this.min) return;
      this.$emit('input', this.value - 1)
    }
  }
}
</script>

<style scoped>

</style>
