import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/services/http';
import util from "@/services/util";

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    user: state => state.user,
    products: state => state.products,
    cart: state => state.cart,
    balance: state => state.balance
  },
  state: {
    isLoggedIn: false,
    user: null,
    products: [],
    cart: [],
    balance: null
  },
  mutations: {
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setUser(state, user) {
      state.user = user;
    },
    setProducts(state, products) {
      state.products = products;
    },
    addToCart(state, product) {
      const productInCart = state.cart.find(p => p.id === product.id);
      if (productInCart) {
        productInCart.amount += product.selectedAmount;
      } else {
        state.cart.push({
          id: product.id,
          amount: product.selectedAmount
        });
      }
      util.saveCart(state.cart);
    },
    removeFromCart(state, product) {
      state.cart = state.cart.filter(p => p.id !== product.id);
      util.saveCart(state.cart);
    },
    setCart(state, cart) {
      state.cart = cart;
      util.saveCart(state.cart);
    },
    setBalance(state, balance) {
      state.balance = balance;
    }
  },
  actions: {
    fetchUser({ commit }) {
      return new Promise((resolve, reject) => {
        http.axios.get('/identity-service/me').then(res => {
          commit('setUser', res.data.data);
          resolve();
        }).catch(e => reject(e));
      });
    },
    fetchProducts({ commit }) {
      return new Promise((resolve, reject) => {
        http.axios.get('/inventory-service/products').then(res => {
          commit('setProducts', res.data.data.map(p => ({
            ...p,
            selectedAmount: p.amount > 0 ? 1 : 0
          })));
          resolve();
        }).catch(e => reject(e));
      });
    },
    fetchBalance({ commit }) {
      return new Promise((resolve, reject) => {
        http.axios.get('/token-service/tokens/balance').then(res => {
          commit('setBalance', res.data.data.balance);
          resolve();
        }).catch(e => reject(e));
      })
    }
  }
})
