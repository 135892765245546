<template>
  <div class="container">
    <div class="box">
      <h1 class="title">Cart</h1>
      <template v-if="productsInCart.length === 0">
        <p>Your cart is empty. Products placed in your cart will be visible here.</p>
      </template>
      <template v-else>
        <table class="cart-table">
          <thead>
            <tr>
              <th></th>
              <th>Item</th>
              <th>Amount</th>
              <th>Price per piece</th>
              <th class="has-text-right">Total price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in productsInCart" :key="product.id">
              <td>
                <close-icon class="delete-icon" @click="removeProduct(product)"/>
              </td>
              <td>{{ product.name }}</td>
              <td>
                <Counter v-model="cart[index].amount" :min="1" :max="product.amount" @input="saveCart" small/>
              </td>
              <td>{{ product.price }} {{ tokenUnit(product.price) }}</td>
              <td class="has-text-right">{{ product.selectedAmount * product.price }} {{ tokenUnit(product.amount * product.price) }}</td>
            </tr>
            <tr>
              <td></td>
              <td><strong>Total</strong></td>
              <td></td>
              <td></td>
              <td class="has-text-right"><strong>{{ totalPrice }} {{ tokenUnit(totalPrice) }}</strong></td>
            </tr>
          </tbody>
        </table>
        <div class="columns pt-4">
          <div class="column">
            <div class="button is-ghost" @click="showQRCode = true">Generate QR code</div>
            <router-link to="/checkout" class="button is-primary is-pulled-right">
              Go to checkout
            </router-link>
          </div>
        </div>

        <div class="modal" :class="{ 'is-active': showQRCode }">
          <div class="modal-background" @click="showQRCode = false"></div>
          <div class="modal-content qrcode-modal">
            <div class="box">
              <canvas ref="qrcode"/>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Counter from "@/components/Counter";
import util from "@/services/util";
import CloseIcon from "@/assets/icons/close.svg";
import QRCode from "qrcode";

export default {
  name: "Cart",
  components: {
    Counter,
    CloseIcon
  },
  computed: {
    productsInCart() {
      return this.cart.map(p => ({
        ...this.products.find(pr => pr.id === p.id),
        selectedAmount: p.amount
      }));
    },
    totalPrice() {
      return this.productsInCart.reduce((acc, curr) => acc + curr.selectedAmount * curr.price, 0)
    },
    ...mapGetters(['cart', 'products'])
  },
  data() {
    return {
      showQRCode: false
    }
  },
  watch: {
    showQRCode(val) {
      if (val) {
        const url = `${window.location.hostname}/checkout?cart=${encodeURIComponent(JSON.stringify(this.cart))}`;
        QRCode.toCanvas(this.$refs.qrcode, url, { width: 600, margin: 0 });
      }
    }
  },
  methods: {
    tokenUnit: util.tokenUnit,
    removeProduct(product) {
      this.$store.commit('removeFromCart', product);
    },
    saveCart() {
      util.saveCart(this.cart);
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-table {
  width: 100%;

  th, td {
    height: 2.5rem;
  }

  .delete-icon {
    cursor: pointer;
  }
}

.qrcode-modal {
  width: auto;
}
</style>
